import React from "react";
import {
  EllipsisVerticalIcon,
  PlusIcon,
  CheckBadgeIcon,
} from "@heroicons/react/20/solid";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { MarkerF, PolylineF } from "@react-google-maps/api";
import pickupMarker from "images/pickup.png";
import dropoffMarket from "images/dropoff.png";
import Swal from "sweetalert2";
import {
  ToastError,
  ToastConfirmedTrip,
  ToastMarkedCancelledTrip,
} from "utils/toasts";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from "components/dropdown";
import { Badge } from "components/badge";
import { Button } from "components/button";
import { MapView } from "components/MapView";

export default function MemberReady() {
  const [tripDetails, setTripDetails] = useState(null);
  console.log("tripDetails", tripDetails);
  let params = useParams();
  const navigate = useNavigate();
  let id = params.tripId;
  let [map, setMap] = useState();
  let [routePath, setRoutePath] = useState([]);

  useEffect(() => {
    if (!id) return;
    getDataFromApi();
  }, [id]);

  const onLoad = (myMap) => {
    map = myMap;
    setMap(myMap);
    if (tripDetails) setTripsBounds(tripDetails);
  };

  function getDataFromApi() {
    fetch(`${process.env.REACT_APP_BASEURL}tripassistances/${id}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setTripDetails(data);
      })
      .catch((error) => {
        console.error("There was a problem fetching the data:", error);
        ToastError(error.message);
      });
  }

  function confirmationTripAlert() {
    Swal.fire({
      title: "Alert",
      icon: "info",
      text: "Are you sure that you require a return ride right now? The driver may not be able to wait outside for long.",
      confirmButtonText: "Yes",
      showCancelButton: true,
      cancelButtonText: `No`,
      focusConfirm: false,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        confirmationTripReq();
      }
    });
  }

  function confirmationTripReq() {
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        status: "confirmed",
      }),
    };
    fetch(
      `${process.env.REACT_APP_BASEURL}tripassistances/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.message) {
          ToastError(data.message);
          return;
        }
        if (data.status === "pending" ? false : true) {
          ToastConfirmedTrip();
        }
        console.log("data", data);
        getDataFromApi();
      });
  }

  function setTripsBounds(data) {
    const pointB = new window.google.maps.LatLng(
      data.assign.jobDestinationLatitude,
      data.assign.jobDestinationLongitude
    );
    const bounds = new window.google.maps.LatLngBounds();
    bounds.extend(pointB);
    // Specify padding levels
    const paddingLevels = 0.0001;
    const padding = {
      lat: paddingLevels,
      lng: paddingLevels,
    };

    const paddedBounds = {
      north: bounds.getNorthEast().lat() + padding.lat,
      south: bounds.getSouthWest().lat() - padding.lat,
      east: bounds.getNorthEast().lng() + padding.lng,
      west: bounds.getSouthWest().lng() - padding.lng,
    };

    map.fitBounds(paddedBounds);
  }

  return (
    <>
      {tripDetails ? (
        <section className="h-dvh relative">
          <div className="relative p-4 max-w-xl mx-auto space-y-8">
            <div className="rounded-md p-2 ring-1 ring-gray-200 flex justify-between items-center">
              <div>
                {tripDetails.company && tripDetails.company.profileImageURL && (
                  <img
                    src={tripDetails.company.profileImageURL}
                    alt={tripDetails.company.displayName}
                    className="p-1 h-10 max-w-32 rounded-md border object-scale-down"
                  />
                )}
              </div>
              <div className="text-sm font-bold text-gray-900">
                Ready to Return
              </div>
              <div>
                <Dropdown>
                  <DropdownButton plain aria-label="More options">
                    <EllipsisVerticalIcon />
                  </DropdownButton>
                  <DropdownMenu>
                    <DropdownItem href={`tel:${tripDetails.contactNumber}`}>
                      Call Dispatcher
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>

            <div className="bg-white rounded-2xl border aspect-[2/1] shadow-lg overflow-hidden">
              <MapView onLoad={onLoad}>
                {tripDetails.assign &&
                  tripDetails.assign.jobDestinationLatitude && (
                    <MarkerF
                      label={{
                        color: "white",
                        fontSize: "11px",
                        fontWeight: "bold",
                        text: "P",
                      }}
                      icon={{
                        url: pickupMarker,
                        scaledSize: {
                          width: 38,
                          height: 38,
                        },
                        labelOrigin: { x: 19, y: 14 },
                      }}
                      position={{
                        lat: tripDetails.assign.jobDestinationLatitude,
                        lng: tripDetails.assign.jobDestinationLongitude,
                      }}
                    />
                  )}
              </MapView>
            </div>
            <div className="bg-[#293562] rounded-2xl overflow-hidden space-y-4">
              <div className="flex flex-col p-6">
                <p className="text-lg text-gray-100">
                  Hi{" "}
                  <span className="font-medium text-yellow-400">
                    {tripDetails.assign.priorityClient
                      ? tripDetails.assign.priorityClient.displayName.split(
                          " "
                        )[0]
                      : "Not Valid Name"}
                  </span>
                  , <br />{" "}
                  {tripDetails.status === "pending"
                    ? "Are you ready for your return trip?"
                    : "You are ready for your trip."}
                </p>
              </div>
              <div className="relative flex items-center after:absolute after:-left-2 after:size-4 after:bg-white  after:rounded-full before:absolute before:-right-2 before:size-4 before:bg-white before:rounded-full px-2">
                <div className="w-full border-t border-dashed border-white/20 mx-2"></div>
              </div>
              <div className="p-6 space-y-8">
                <ul role="list" className="space-y-6">
                  <li className="relative flex gap-x-4">
                    <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-[#293562]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-3 w-3 text-green-400"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill="currentColor"
                          d="M13.756 10.164c1.665-.962 1.665-3.366 0-4.328L5.251.919C3.584-.045 1.5 1.158 1.5 3.083v9.834c0 1.925 2.084 3.128 3.751 2.164z"
                        />
                      </svg>
                    </div>
                    <div className="flex-auto space-y-1">
                      <p className="pt-1 text-sm text-gray-100">
                        Pickup Address
                      </p>
                      <p className="text-base text-white font-medium underline decoration-dashed">
                        {tripDetails.assign.jobDestinationAddress
                          ? tripDetails.assign.jobDestinationAddress
                          : "Not Valid Address"}
                      </p>
                    </div>
                  </li>
                </ul>
                {tripDetails.status === "pending" ? (
                  <Button
                    color="green"
                    className="w-full"
                    onClick={() => confirmationTripAlert()}
                  >
                    Yes! I am Ready
                  </Button>
                ) : (
                  <div className="rounded-md bg-green-50 p-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <CheckBadgeIcon className="h-5 w-5 text-green-400" />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm font-medium text-green-800">
                          Marked Ready!
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="relative flex items-center after:absolute after:-left-2 after:size-4 after:bg-white after:rounded-full before:absolute before:-right-2 before:size-4 before:bg-white before:rounded-full px-2">
                <div className="w-full border-t border-dashed border-white/20 mx-2"></div>
              </div>
              <p className="text-base text-red-300 px-6 pb-8 pt-4">
                Please ensure your presence at the designated location so that
                the driver can easily reach you for boarding.
              </p>
            </div>
          </div>
        </section>
      ) : (
        <SkeletonTheme>
          <section className="h-dvh relative">
            <div className="relative p-4 max-w-xl mx-auto space-y-8">
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-2">
                  <Skeleton height={48} width={48} circle={true} />
                  <Skeleton height={16} width={120} />
                </div>
                <Skeleton height={32} width={32} />
              </div>
              <div>
                <Skeleton height={196} />
              </div>
              <div>
                <Skeleton count={12} />
              </div>
              <div className="grid grid-cols-2 gap-4">
                <Skeleton height={48} />
                <Skeleton height={48} />
              </div>
            </div>
          </section>
        </SkeletonTheme>
      )}
    </>
  );
}

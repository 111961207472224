import React from "react";
import NotFoundImage from "images/404.png";

export default function NotFound() {
  return (
    <section className="h-dvh grid place-content-center p-4">
      <img
        src={NotFoundImage}
        className="h-80 w-auto object-scale-down"
        height={400}
        width={600}
        alt=""
      />
    </section>
  );
}

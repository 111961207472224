import React, { useEffect } from 'react';
import { useGeolocated } from 'react-geolocated';

const LocationTracker = ({ onLocationUpdate }) => {
    const { isGeolocationAvailable, isGeolocationEnabled, coords } = useGeolocated({
        watchPosition: true, // Enable continuous location updates
    });

    useEffect(() => {
        // Ensure geolocation is available and enabled, and coordinates are present
        if (isGeolocationAvailable && isGeolocationEnabled && coords) {
            // Pass the updated location to the callback function
            onLocationUpdate({ latitude: coords.latitude, longitude: coords.longitude });
        }
    }, [isGeolocationAvailable, isGeolocationEnabled, coords, onLocationUpdate]);

    // This component doesn't render anything visible, so it returns null
    return null;
};

export default LocationTracker;

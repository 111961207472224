import React, { useState, useEffect } from "react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { ToastError } from "utils/toasts";
import TripConfirmationImage from "images/trip-confirmation.png";
import TrackRideImage from "images/track-ride.png";
import CallDispatcherImage from "images/call-dispatcher.png";
import ActivationReturnImage from "images/activation-return.png";
import Moment from "react-moment";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from "components/dropdown";
import { Button } from "components/button";
import { Link } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";

export default function Dashboard() {
  const navigate = useNavigate();
  const [tripDetails, setTripDetails] = useState(null);
  console.log("tripDetails", tripDetails);
  let params = useParams();
  let id = params.tripId;

  useEffect(() => {
    if (!id) return;
    postData();
  }, [id]);

  let assignId = id;

  function postData() {
    // POST request using fetch inside useEffect React hook
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      // body: JSON.stringify({ title: "React Hooks POST Request Example" }),
      body: JSON.stringify({ assignId }),
    };
    fetch(`${process.env.REACT_APP_BASEURL}assigns/memberlink`, requestOptions)
      // .then((response) => response.json())
      .then((response) => {
        if (!response.ok) {
          // navigate("/expired");
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setTripDetails(data);
      })
      .catch((error) => {
        // navigate("/expired");
        console.error("There was a problem fetching the data:", error);
        ToastError(error.message);
      });
  }

  return (
    <>
      {tripDetails ? (
        <section className="h-dvh relative">
          <div className="relative p-4 max-w-xl mx-auto space-y-8">
            <div className="rounded-md p-2 ring-1 ring-gray-200 flex justify-between items-center">
              <div>
                {tripDetails.assign &&
                  tripDetails.assign.company &&
                  tripDetails.assign.company.profileImageURL && (
                    <img
                      src={tripDetails.assign.company.profileImageURL}
                      alt={tripDetails.assign.company.displayName}
                      className="p-1 h-10 w-24 rounded-md object-scale-down"
                    />
                  )}
              </div>
              <div className="flex-1 flex justify-center">
                <div className="text-sm font-bold text-gray-900">
                  Patient Portal
                </div>
              </div>
              <div className="w-24"></div>
            </div>
            <div className="bg-[#293562] rounded-2xl overflow-hidden space-y-4">
              <div className="flex flex-col p-6 space-y-8">
                <p className="text-lg text-gray-100">
                  Hi{" "}
                  <span className="font-medium text-yellow-400">
                    {tripDetails.assign
                      ? tripDetails.assign.priorityClient.displayName.split(
                          " "
                        )[0]
                      : "Not Valid Name"}
                  </span>
                  , This is your self service portal provided by you transport
                  provider{" "}
                  <span className="font-bold text-yellow-400">
                    {tripDetails.assign && (
                      <>{tripDetails.assign.company.displayName}</>
                    )}
                  </span>
                </p>
                <div className="grid grid-cols-3 gap-4 items-center">
                  <div className="flex flex-col text-center">
                    <div className="text-sm font-semibold text-white leading-5 underline decoration-dashed">
                      {tripDetails.assign ? (
                        <Moment format="D MMM YYYY">
                          {tripDetails.assign.scheduleTime}
                        </Moment>
                      ) : (
                        "Not Valid Date"
                      )}
                    </div>
                    <div className="text-xs text-gray-100 leading-5">
                      Date of Service
                    </div>
                  </div>
                  <div className="flex flex-col text-center">
                    <div className="text-sm font-semibold text-white leading-5 underline decoration-dashed">
                      {tripDetails.assign ? (
                        <> {tripDetails.assign.spaceType.title}</>
                      ) : (
                        "Not Valid Date"
                      )}
                    </div>
                    <div className="text-xs text-gray-100 leading-5">
                      Service
                    </div>
                  </div>
                  <div className="flex flex-col items-end">
                    <div className="text-sm font-bold text-primary-500 leading-tight rounded-full px-2 py-1 ring-1 ring-primary-500 bg-green-100">
                      {tripDetails && tripDetails.assign.status}
                    </div>
                  </div>
                </div>
              </div>
              <div className="relative flex items-center after:absolute after:-left-2 after:size-4 after:bg-white  after:rounded-full before:absolute before:-right-2 before:size-4 before:bg-white before:rounded-full px-2">
                <div className="w-full border-t border-dashed border-white/20 mx-2"></div>
              </div>
              <div className="px-6 py-2 space-y-8">
                <ul role="list" className="space-y-6">
                  <li className="relative flex gap-x-4">
                    <div className="absolute left-0 top-0 flex w-6 justify-center -bottom-6">
                      <div className="w-px  border-r border-gray-200 border-dashed"></div>
                    </div>
                    <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-[#293562]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-3 w-3 text-green-400"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill="currentColor"
                          d="M13.756 10.164c1.665-.962 1.665-3.366 0-4.328L5.251.919C3.584-.045 1.5 1.158 1.5 3.083v9.834c0 1.925 2.084 3.128 3.751 2.164z"
                        />
                      </svg>
                    </div>
                    <div className="flex-auto space-y-1">
                      <p className="pt-1 text-xs text-gray-100">
                        Pickup Address & Time
                      </p>
                      <p className="text-sm text-white font-medium underline decoration-dashed">
                        {tripDetails && tripDetails.assign.jobOriginAddress}
                      </p>
                    </div>
                    <div className="flex-none">
                      <time
                        dateTime="2023-01-23T11:03"
                        className="text-sm font-bold text-primary-500 leading-tight rounded-full px-2 py-1 ring-1 ring-primary-500 bg-green-100"
                      >
                        {tripDetails.assign && (
                          <Moment format="hh:mm A">
                            {tripDetails.assign.scheduleTime}
                          </Moment>
                        )}
                      </time>
                    </div>
                  </li>
                  <li className="relative flex gap-x-4">
                    <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-[#293562]">
                      {/* <div className="h-2 w-2 rounded-sm bg-red-200 ring-1 ring-red-400"></div> */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-3 w-3 text-red-400"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="currentColor"
                          d="M2 12c0-4.714 0-7.071 1.464-8.536C4.93 2 7.286 2 12 2c4.714 0 7.071 0 8.535 1.464C22 4.93 22 7.286 22 12c0 4.714 0 7.071-1.465 8.535C19.072 22 16.714 22 12 22s-7.071 0-8.536-1.465C2 19.072 2 16.714 2 12"
                        />
                      </svg>
                    </div>
                    <div className="flex-auto space-y-1">
                      <p className="pt-1 text-xs text-gray-100">
                        Dropoff Address & Time
                      </p>
                      <p className="text-sm text-white font-medium underline decoration-dashed">
                        {tripDetails &&
                          tripDetails.assign.jobDestinationAddress}
                      </p>
                    </div>
                    <div className="flex-none">
                      <time
                        dateTime="2023-01-23T11:03"
                        className="text-sm font-bold text-primary-500 leading-tight rounded-full px-2 py-1 ring-1 ring-primary-500 bg-green-100"
                      >
                        {tripDetails && (
                          <Moment format="hh:mm A">
                            {tripDetails.assign.appointmentTime}
                          </Moment>
                        )}
                      </time>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="relative flex items-center after:absolute after:-left-2 after:size-4 after:bg-white  after:rounded-full before:absolute before:-right-2 before:size-4 before:bg-white before:rounded-full px-2">
                <div className="w-full border-t border-dashed border-white/20 mx-2"></div>
              </div>
              <div className="p-6 space-y-8">
                <div className="flex flex-col space-y-6">
                  <h3 className="text-lg text-white">
                    Please choose from below options
                  </h3>
                  <div className="grid grid-cols-1 gap-4">
                    {tripDetails.assign &&
                    !tripDetails.assign.isConfirmedTrip &&
                    ["unassigned", "offered", "accepted"].includes(
                      tripDetails.assign.status
                    ) ? (
                      <Link to={`/trip-confirmation/${id}`}>
                        <div className="flex items-center text-left gap-2 border p-2 rounded-xl bg-green-100">
                          <div className="w-16 h-12">
                            <img
                              src={TripConfirmationImage}
                              className="h-full w-full object-scale-down"
                              alt=""
                            />
                          </div>
                          <div className="text-lg font-medium text-gray-900 leading-5">
                            Trip<span className="">Confirmation</span>
                          </div>
                        </div>
                      </Link>
                    ) : (
                      <div className="flex items-center text-left gap-2 border p-2 rounded-xl bg-gray-200 cursor-not-allowed">
                        <div className="w-16 h-12">
                          <img
                            src={TripConfirmationImage}
                            className="h-full w-full object-scale-down"
                            alt=""
                          />
                        </div>
                        <div className="text-lg font-medium text-gray-500 leading-5">
                          Trip <span>Confirmation</span>
                        </div>
                      </div>
                    )}
                    {tripDetails.assign &&
                    tripDetails.assign.status === "inprogress" ? (
                      <Link to={`/trip-tracking/${id}`}>
                        <div className="flex items-center text-left gap-2 border p-2 rounded-xl bg-blue-100">
                          <div className="w-16 h-12">
                            <img
                              src={TrackRideImage}
                              className="h-full w-full object-scale-down"
                              alt=""
                            />
                          </div>
                          <div className="text-lg font-medium text-gray-900 leading-5">
                            Track Ride<span className=""> (ETA)</span>
                          </div>
                        </div>
                      </Link>
                    ) : (
                      <div className="flex items-center text-left gap-2 border p-2 rounded-xl bg-gray-200 cursor-not-allowed">
                        <div className="w-16 h-12">
                          <img
                            src={TrackRideImage}
                            className="h-full w-full object-scale-down"
                            alt="Track Ride Unavailable"
                          />
                        </div>
                        <div className="text-lg font-medium text-gray-500 leading-5">
                          Track Ride <span className=""> (ETA)</span>
                        </div>
                      </div>
                    )}
                    {tripDetails.assign &&
                      tripDetails.assign.status === "finished" &&
                      tripDetails.assign.company &&
                      tripDetails.assign.company.isTripAssistance &&
                      tripDetails.tripassistance &&
                      tripDetails.tripassistance.status === "pending" && (
                        <Link
                          to={`/trip-member-ready/${tripDetails.tripassistance._id}`}
                        >
                          <div className="flex items-center text-left gap-2 border p-2 rounded-xl bg-orange-100">
                            <div className="w-16 h-12">
                              <img
                                src={ActivationReturnImage}
                                className="h-full w-full object-scale-down"
                                alt=""
                              />
                            </div>
                            <div className="text-lg font-medium text-gray-900 leading-5">
                              Activate<span className=""> Return</span>
                            </div>
                          </div>
                        </Link>
                      )}
                    {tripDetails.assign.company && (
                      <Link to={`tel:${tripDetails.assign.company.extension}`}>
                        <div className="flex items-center text-left gap-2 border p-2 rounded-xl bg-zinc-100">
                          <div className="w-16 h-12">
                            <img
                              src={CallDispatcherImage}
                              className="h-full w-full object-scale-down"
                              alt=""
                            />
                          </div>
                          <div className="text-lg font-medium text-gray-900 leading-5">
                            Call <span className=""> Dispatcher</span>
                          </div>
                        </div>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <SkeletonTheme>
          <section className="h-dvh relative">
            <div className="relative p-4 max-w-xl mx-auto space-y-8">
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-2">
                  <Skeleton height={48} width={48} circle={true} />
                  <Skeleton height={16} width={120} />
                </div>
                <Skeleton height={32} width={32} />
              </div>
              <div>
                <Skeleton height={196} />
              </div>
              <div>
                <Skeleton count={12} />
              </div>
              <div className="grid grid-cols-2 gap-4">
                <Skeleton height={48} />
                <Skeleton height={48} />
              </div>
            </div>
          </section>
        </SkeletonTheme>
      )}
    </>
  );
}
